// Globale Funktionen (Scripts)
(function ($) {
    /* GLOBAL FUNCTIONS ***********************************************************************************************/
    SITE.funcs.urlParam = function (name) {
        var results = new RegExp('[\?&]' + name + '=([^&#]*)').exec(window.location.href);
        if (results == null) {
            return null;
        }
        else {
            return results[1] || 0;
        }
    };

    // Funktion um Fehler auf der Console auszugeben
    SITE.funcs.logError = function (name, exception) {
        console.log('ERROR (' + name + ')');
        console.log(exception);
    };
})(window.jQuery);