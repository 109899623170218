require('../libraries/jquery.unveil.js');
var BigPicture = require('bigpicture');
window.BigPicture = BigPicture;


// Funktionen für Bildmanipulationen etc.
(function($) {
    /* INIT ***********************************************************************************************************/
    SITE.onInit.push(function() {
        initLazyLoad();
        initBigPicture();
    });

    /* PRIVATE FUNCTIONS **********************************************************************************************/
    function initLazyLoad() {
        /* Lazy-Loading von Bildern */
        $('img.lazy').unveil(600, function () {
            $(this).on('load', function () {
                this.style.opacity = 1;
            });
        });
    }

    function initBigPicture() {
        // BigPicture Lightbox-Initialisierung
        $('.lightbox img').click(function(e){
            e.preventDefault();
            BigPicture({
                el: this,
                gallery: document.querySelectorAll('.lightbox img')
            })
        });
    }
})(window.jQuery);



