// POLYFILLS ///////////////////////////////////////////////////////////////////////////////////////////////////////////
import '@webcomponents/custom-elements'; // Polyfill Webcomponents custom-elements IE, Edge und alte iOS etc.

//import cssVars from 'css-vars-ponyfill'; // Polyfill für CSS Variablen (Chrome 19+, Edge 12+, Firefox 6+, IE 9+, Safari 6+)
//window.cssVars = cssVars;

import objectFitImages from 'object-fit-images'; // Polyfill IE9, IE10, IE11, Edge, Safari...
window.objectFitImages = objectFitImages;
require('../libraries/picturefill.min.js'); // Polyfill IE9

// Polyfill-Funktionen/Aufrufe
(function() {
    /* INIT ***********************************************************************************************************/
    SITE.onInit.push(function() {
        objectFitImages();

        //cssVars({
        //    // Options...
        //});
    });
})();