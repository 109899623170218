// Funktionen für die Navigation
(function($) {
    /* INIT ***********************************************************************************************************/
    SITE.onInit.push(function() {
        initializeSearchbox();
        adjustMenuDisplay();
    });

    /* RESIZE *********************************************************************************************************/
    SITE.onResize.push(function() {
        adjustMenuDisplay();
    });

    /* SCROLL *********************************************************************************************************/
    SITE.onScroll.push(function() {
        adjustMenuDisplay();
    });

    /* PRIVATE FUNCTIONS **********************************************************************************************/
    function adjustMenuDisplay() {
        $('#page').addClass('fade-in');
    }

    function initializeSearchbox() {
        //Suche öffnen und schließen
        var magnifier = $("#magnifier");

        magnifier.click(function() {
            $("#search_menu").toggleClass("open");
            magnifier.toggleClass("active");
            $("#close-search").toggleClass("active");

            if ($("#search_menu").hasClass("open")) {
                $('#tx-indexedsearch-searchbox-sword').focus();
            }
        });
    }
})(window.jQuery);